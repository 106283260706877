import {
  Autocomplete,
  Box,
  Fade,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useGetCountriesQuery } from '../../api/api';
import { FADE_TIMEOUT, createStylesUtils } from '../../common/common';
import { appEnvironment, autoCompleteData } from '../../common/environment';
import { Country } from '../../data/countries';
import { useShoppingCart } from '../../onboarding/common/hooks';
import { formStyles } from '../../onboarding/styles';
import { ContinueRef } from '../../onboarding/types';
import { checkoutActions } from '../../redux/checkout';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import RoundBackgroundInput from '../input/RoundBackgroundInput';
import { OnboardingV2Step } from '../../redux/onboardingTypes';
import { onboardingActions } from '../../redux/onboarding';
import { useSYDMessages } from '../../common/v2/hooks';
import { useTranslation } from 'react-i18next';
import { useMobile } from '../../common/hooks';

interface FieldValue {
  value: string;
  error: string;
}

interface State {
  addressLine1: FieldValue;
  addressLine2: FieldValue;
  city: FieldValue;
  postCode: FieldValue;
}

type StateKey = keyof State;

const AddressV2 = forwardRef<ContinueRef, Props>((props, ref) => {
  const { data: countries } = useGetCountriesQuery(false);
  if (!countries) {
    return null;
  }

  return <UI {...props} countries={countries} ref={ref} />;
});

const UI = forwardRef<ContinueRef, Props & { countries: Country[] }>(
  (props, ref) => {
    const { countries } = props;

    useImperativeHandle(ref, () => ({
      continuePressed: async () => {
        if (type === 'billing') {
          dispatch(checkoutActions.setBillingAddress(address));
        } else {
          dispatch(checkoutActions.setDeliveryAddress(address));
        }
        dispatch(onboardingActions.setOnboardingStep(nextStep));
      },
    }));

    const { type } = props;
    const { t } = useTranslation();

    const [country, setCountry] = useState<Country | null>(
      countries.find((item) => item.code === 'GB')!
    );
    const [countryError, setCountryError] = useState('');
    const [state, setState] = useState<State>({
      addressLine1: {
        value: appEnvironment.autocomplete
          ? autoCompleteData.address.addressLine1
          : '',
        error: '',
      },
      addressLine2: {
        value: appEnvironment.autocomplete
          ? autoCompleteData.address.addressLine2
          : '',
        error: '',
      },
      city: {
        value: appEnvironment.autocomplete ? autoCompleteData.address.city : '',
        error: '',
      },
      postCode: {
        value: appEnvironment.autocomplete
          ? autoCompleteData.address.postCode
          : '',
        error: '',
      },
    });

    const dispatch = useAppDispatch();

    const { showMessages } = useSYDMessages();
    const hasShownMessagesRef = useRef(false);

    const gender = useAppSelector(
      (state) => state.checkout.personalData.gender
    );
    const messages = useAppSelector((state) => state.onboarding.sydMessages);

    const reduxToGender = useMemo(() => {
      switch (gender) {
        case 'F':
          return t('female');
        case 'NB':
          return t('nonBinary');
        case 'M':
        default:
          return t('male');
      }
    }, [gender, t]);

    useEffect(() => {
      if (!hasShownMessagesRef.current) {
        hasShownMessagesRef.current = true;
        if (messages.map((item) => item.text).includes(reduxToGender)) {
          return;
        }

        showMessages([reduxToGender, t('whereShouldIShip')], 0, true);
      }
    }, [messages, reduxToGender, showMessages, t]);

    const hasError = useMemo(() => {
      const keys = Object.keys(state).map((key) => key as StateKey);
      for (const key of keys) {
        if (key !== 'addressLine2' && state[key].value.length === 0) {
          return true;
        }
      }

      return country === null;
    }, [country, state]);

    useEffect(() => {
      dispatch(onboardingActions.setButtonEnabled(!hasError));
    }, [dispatch, hasError]);

    const address = useMemo(() => {
      return {
        addressLine1: state.addressLine1.value,
        addressLine2: state.addressLine2.value,
        city: state.city.value,
        postCode: state.postCode.value,
        country: country !== null ? country.code : '',
      };
    }, [
      country,
      state.addressLine1.value,
      state.addressLine2.value,
      state.city.value,
      state.postCode.value,
    ]);

    const { isPremium } = useShoppingCart();

    const nextStep = useMemo(() => {
      if (type === 'billing') {
        if (isPremium) {
          return OnboardingV2Step.DELIVERY_ADDRESS;
        } else {
          return OnboardingV2Step.SUMMARY;
        }
      } else {
        return OnboardingV2Step.SUMMARY;
      }
    }, [isPremium, type]);

    const onBlur = useCallback(
      (key: StateKey) => {
        return () => {
          if (key !== 'addressLine2' && state[key].value.length === 0) {
            setState((state) => ({
              ...state,
              [key]: { ...state[key], error: t('thisFieldIsRequired') },
            }));
          }
        };
      },
      [state, t]
    );

    const onChange = useCallback((key: StateKey) => {
      return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setState((state) => ({
          ...state,
          [key]: { error: '', value: e.target.value },
        }));
      };
    }, []);

    const mobile = useMobile();

    return (
      <Fade in={true} timeout={FADE_TIMEOUT} appear={true}>
        <Box
          sx={[
            formStyles.container,
            mobile ? { padding: '120px 16px 0px', flex: 1 } : {},
          ]}
        >
          <Box sx={formStyles.formContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RoundBackgroundInput
                  placeholder={t('adressLine1')}
                  value={state.addressLine1.value}
                  error={state.addressLine1.error.length > 0}
                  helperText={state.addressLine1.error}
                  onChange={onChange('addressLine1')}
                  onBlur={onBlur('addressLine1')}
                />
              </Grid>
              <Grid item xs={12}>
                <RoundBackgroundInput
                  placeholder={t('adressLine2')}
                  helperText={t('optional')}
                  value={state.addressLine2.value}
                  onChange={onChange('addressLine2')}
                  onBlur={onBlur('addressLine2')}
                  sx={{
                    '& .MuiFormHelperText-root': {
                      color: '#292D3266',
                      fontSize: '10px',
                      fontWeight: '500px',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <RoundBackgroundInput
                  placeholder={t('city')}
                  value={state.city.value}
                  error={state.city.error.length > 0}
                  helperText={state.city.error}
                  onChange={onChange('city')}
                  onBlur={onBlur('city')}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <RoundBackgroundInput
                  placeholder={t('postcode')}
                  value={state.postCode.value}
                  error={state.postCode.error.length > 0}
                  helperText={state.postCode.error}
                  onChange={onChange('postCode')}
                  onBlur={onBlur('postCode')}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  options={countries}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={styles.flagContainer}
                      >
                        <img src={option.flag} className="flag" />
                        <Typography sx={{ marginLeft: '12px' }}>
                          {option.label}
                        </Typography>
                      </Stack>
                    </Box>
                  )}
                  autoHighlight={true}
                  onChange={(_event, value) => {
                    setCountry(value);
                  }}
                  value={country}
                  getOptionLabel={(option) => option.label}
                  renderInput={(props) => (
                    <RoundBackgroundInput
                      {...props}
                      placeholder={t('country')}
                      onBlur={() => {
                        if (country === null) {
                          setCountryError(t('thisFieldIsRequired'));
                        }
                      }}
                      error={countryError.length > 0}
                      helperText={countryError}
                      onFocus={() => {
                        setCountryError('');
                      }}
                      InputProps={{
                        ...props.InputProps,
                        endAdornment: (
                          <Box sx={styles.flagContainer}>
                            {country && (
                              <img src={country.flag} className="flag" />
                            )}
                            {props.InputProps.endAdornment}
                          </Box>
                        ),
                      }}
                    />
                  )}
                  sx={{ '& .MuiFilledInput-root': { paddingTop: '0px' } }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Fade>
    );
  }
);

export default AddressV2;

interface Props {
  type: 'billing' | 'delivery';
}

const styles = createStylesUtils({
  flagContainer: {
    '& .flag': {
      width: '36px',
      height: '36px',
      borderRadius: '18px',
    },
  },
});
